import React, { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import qs from 'query-string';
import moment, { Moment } from 'moment';
import { ReactComponent as GentLogo } from './gent-logo.svg';
import { ReactComponent as StudentGentLogo } from './student-gent-logo.svg';
import CountdownView from './CountdownView';
import Consent from './Consent';
import { CookiesProvider } from 'react-cookie';

const testDate = qs.parse(window.location.search).testDate?.toString();
const toDate = moment(testDate || '2024-09-25T07:00:00Z');

const getStateValue = () => {
  const diffDuration = moment.duration(toDate.diff(moment.utc()));
  const hours = Math.floor(diffDuration.asHours());
  const minutes = diffDuration.get('minutes');
  const seconds = diffDuration.get('seconds');

  return {
    hours,
    minutes,
    seconds
  }
}

let timer: any = null;

function App() {
  const [current, setCurrent] = useState<AppState>({
    value: getStateValue()
  });
  const [bg, setBg] = useState<{ lastUpdate?: Moment }>({ lastUpdate: undefined });

  const passed = moment.utc().isAfter(toDate) || moment.utc().isSame(toDate);
  const { value } = current;
  const { hours, minutes, seconds } = value;

  let img = !passed ? 60 - Math.abs(seconds) : Math.abs(seconds);
  img = img === 0 ? 60 : img;
  let nextImg = !passed ? img - 1 : img + 1;

  const setScssHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  const updateState = () => {
    setCurrent({ value: getStateValue() });
  }

  const updateBg = () => {
    var image = new Image();
    image.src = `bg/${window.innerWidth > window.innerHeight ? '' : 'mobile/'}${img}.jpg`;
    image.onload = () => {
      preloadNextImage();
      document.body.style.backgroundImage = `url('bg/${window.innerWidth > window.innerHeight ? '' : 'mobile/'}${img}.jpg')`
      // document.body.style.transition = 'background-image 0.2s ease-in-out';
      setBg({ lastUpdate: moment.utc() })
    };
    image.onerror = () => {
      document.body.style.backgroundImage = `url('bg/${window.innerWidth > window.innerHeight ? '' : 'mobile/'}${!passed ? 60 : 1}.jpg')`
      setBg({ lastUpdate: moment.utc() });
    }
  }

  const preloadNextImage = () => {
    var image = new Image();
    image.src = `bg/${window.innerWidth > window.innerHeight ? '' : 'mobile/'}${nextImg}.jpg`;
  }

  useEffect(() => {
    setScssHeight();
    window.addEventListener('resize', setScssHeight);
    timer = setInterval(updateState, 1000);
    if (!bg.lastUpdate || moment.utc().diff(bg.lastUpdate, 'milliseconds') > 750) {
      updateBg();
    }
    return () => {
      clearInterval(timer);
      window.removeEventListener('resize', setScssHeight);
    }
  });

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <div className="container">
        <div className="foreground">
          <div className="flex-container">
            <div className="brand-logo">
              <GentLogo className="brand-logo-svg" fill="white" />
            </div>
            <div className="flex-wrapper">
              <CountdownView passed={passed} hours={Math.abs(hours)} minutes={Math.abs(minutes)} seconds={Math.abs(seconds)} />
            </div>
            <div className="footer">
              <div className="footer-logo">
                <a href="https://stad.gent/nl/student-in-gent" target="_blank">
                  <StudentGentLogo fill="white" className="footer-logo-svg" viewBox="50 60 275 200" />
                </a>
              </div>
              <div className="footer-ig">
                <a target="_blank" href="https://www.instagram.com/studentingent.be/?hl=nl">
                  <img src="ig.png" alt="Instagram @studentingent.be" /> Voor random tips
                </a>
              </div>
            </div>
          </div>
        </div>
        <Consent />
      </div>
    </CookiesProvider>
  );
}

export default App;
