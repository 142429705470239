import { initGA } from './ga-utils';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

let initialized = false;

export default () => {
    const [cookies, setCookie, removeCookie] = useCookies(['ad_storage', 'analytics_storage', 'rcl_consent_given', 'rcl_marketing_consent', 'rcl_statistics_consent']);

    const init = () => {
        if (!initialized && cookies.ad_storage === 'granted' && cookies.analytics_storage === 'granted') {
            if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
                console.log('GA INIT');
                initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
                initialized = true;
            } else {
                initialized = false;
            }
        }
    };

    useEffect(() => {
        if (cookies.rcl_consent_given) {
            init();
        }
    }, []);

    return (
        <CookieBanner
            message="We gebruiken cookies om verkeer en voorkeuren bij te houden, ter verbetering van de gebruikerservaring."
            showPreferencesOption={false}
            statisticsOptionText="Statistieken"
            necessaryOptionText="Noodzakelijke"
            marketingOptionText="Marketing"
            acceptSelectionButtonText="Opslaan"
            acceptAllButtonText="Alles accepteren"
            showAcceptSelectionButton
            statisticsOptionInitiallyChecked
            marketingOptionInitiallyChecked
            privacyPolicyLinkText="Info over het cookie beleid"
            policyLink="https://stad.gent/nl/cookiebeleid-stad-gent"
            onAcceptStatistics={() => {
                if (cookies.analytics_storage !== 'granted') {
                    setCookie('analytics_storage', 'granted');
                    init();
                }
            }}
            onAcceptMarketing={() => {
                if (cookies.ad_storage !== 'granted') {
                    setCookie('ad_storage', 'granted');
                    init();
                }
            }}
            onDeclineStatistics={() => {
                if (cookies.analytics_storage !== 'denied') {
                    setCookie('analytics_storage', 'denied');
                }
            }}
            onDeclineMarketing={() => {
                if (cookies.ad_storage !== 'denied') {
                    setCookie('ad_storage', 'denied');
                }
            }}
        />
    )
}