import React from 'react';

export default (props: { passed: boolean } & CountdownValue) => (
    <div className="countdown-container">
        {!props.passed && <div className="countdown-header">
            Dju, nog
        </div>}
        {props.passed && <div className="countdown-header-final">
            <img src="tzal-wel-zijn.png" alt="tzal-wel-zijn.png" width="100%" />
            <div>Ge zijt hier al</div>
        </div>}
        <div className="countdown">
            <div className="countdown-value">
                <div className="countdown-value-hours">
                    <div className="countdown-digit">
                        {props.passed ? props.hours -1 : props.hours}
                    </div>
                    <div className='countdown-marker'>UUR</div>
                </div>
                <div className="countdown-value-divider">:</div>
                <div className="countdown-value-minutes">
                    <div className="countdown-digit">
                        {props.minutes.toString().padStart(2, "0")}
                    </div>
                    <div className='countdown-marker'>MIN</div>
                </div>
                <div className="countdown-value-divider">:</div>
                <div className="countdown-value-seconds">
                    <div className="countdown-digit">
                        {props.seconds.toString().padStart(2, "0")}
                    </div>
                    <div className='countdown-marker'>SEC</div>
                </div>
            </div>
        </div>
    </div>
);